import React, { useState } from 'react';
import Axios from 'axios';
import { Link, useLocation } from 'react-router-dom';

import InputField from '../components/InputField';
import Button from '../components/Button';

function ForgotPassword() {
    const location = useLocation();
    const username = location.state?.username || '';
    const [formData, setFormData] = useState({
        username: username,
        password: '',
        confirmPassword: '',
    });
    const [statusMessage, setStatusMessage] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [showLink, setShowLink] = useState(true);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const validatePasswords = () => formData.password === formData.confirmPassword;

    const resetPassword = async (event) => {
        event.preventDefault();

        if (!validatePasswords()) {
            setStatusMessage('Your password and confirmation do not match.');
            return;
        }

        try {
            setShowLoader(true);
            const url = `${process.env.REACT_APP_HOST}/forgot`;
            const response = await Axios.post(url, {
                username: formData.username,
                password: formData.password,
            });
            setStatusMessage(response.data.message);
            setShowLink(true);
        } catch (error) {
            setStatusMessage('There was a problem resetting your password.');
        } finally {
            setShowLoader(false);
        }
    };

    return (
        <div className="forgot">
            <h2>Change Password</h2><br/>
            <form onSubmit={resetPassword}>
                <InputField
                    label="Username&nbsp;"
                    type="text"
                    placeholder="Current Username..."
                    value={formData.username}
                    onChange={handleInputChange}
                    name="username"
                />

                <InputField
                    label="New Pass&nbsp;"
                    type="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    name="password"
                />
                
                <InputField
                    label="Confirm&nbsp;&nbsp;"
                    type="password"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    name="confirmPassword"
                />
                <br/>

                <div className="buttonContainer">
                    <Button
                        text="Reset Password"
                        loading={showLoader}
                        disabled={showLoader}
                    />
                </div>
            </form>

            <br/>
            <div className="status">
                <h3>{statusMessage}</h3>
                {showLink && (
                    <h3 style={{ textDecoration: 'underline' }}>
                        <Link to="/" className="returnHome">
                            Return to Home
                        </Link>
                    </h3>
                )}
            </div>
        </div>
    );
}

export default ForgotPassword;
