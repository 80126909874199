/*  Button to Return Home
    Available on Password Reset & Register Pages
*/
import { Link } from 'react-router-dom';

function HomeButton() {
    return (
        <Link to="/">
            <img src="logo192.png" alt="Return Home" title="Return Home" id="btnHome" />
        </Link>
    );
}

export default HomeButton;