/* Gifts for the logged-in user. */

import React, {useState} from 'react';
import Axios from 'axios';

import InputField from './InputField';
import Button from './Button';

function UserGift({statusMessage, setStatusMessage, gifts, setGifts}) {
    const [formData, setFormData] = useState({
            gift: '',
            cost: '',
            link: '',
    });
    const [showLoader, setShowLoader] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    Axios.defaults.withCredentials = true;

    const addGift = (event) => {
        event.preventDefault();
        setShowLoader(true);
        const url = `${process.env.REACT_APP_HOST}/addGift`;
        Axios.post(url, {
            gift: formData.gift,
            cost: formData.cost,
            link: formData.link,
        }).then((response) => {
            setShowLoader(false);
            if (response.data.message) {
                setStatusMessage(response.data.message);
            } else {
                const newGift = {
                    gift: formData.gift,
                    price: parseFloat(formData.cost),
                    link: formData.link,
                    key: response.data.key,
                };
                const updatedGifts = [...gifts, newGift].sort((a, b) =>
                    a.gift.localeCompare(b.gift)
                );

                setGifts(updatedGifts);
                setStatusMessage(formData.gift + " has been added to your Christmas list.");
                setFormData({ gift: "", cost: "", link: "" });
                setTimeout(() => setStatusMessage(''), 5000);
            }
          }).catch((error) => {
            setStatusMessage("There was a problem adding the gift to your Christmas list.");
            setShowLoader(false);
        });
    };

    return (
        <div id="addGift">
            <form id="giftForm" onSubmit={addGift}>
                <InputField
                    label="Name of Gift&nbsp;"
                    value={formData.gift}
                    onChange={handleInputChange}
                    name="gift"
                />

                <InputField
                    label="Cost of Gift&nbsp;"
                    type="number"
                    value={formData.cost}
                    onChange={handleInputChange}
                    name="cost"
                />

                <InputField
                    label="Link to Gift&nbsp;"
                    type="url"
                    value={formData.link}
                    placeholder="Optional"
                    onChange={handleInputChange}
                    name="link"
                />
                <br />
                <div className="buttonContainer giftContainer">
                    <Button text="Add Gift" loading={showLoader} disabled={showLoader} />
                    <h3>{statusMessage}</h3>
                </div>
            </form>
        </div>
    );
}

export default UserGift;