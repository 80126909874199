import React, { useState, useMemo, useEffect } from 'react';

// Randomly pick a background image
// Add images to the following list and the public/images folder to add more
const bgImages = ['images/2021cousins.jpg','images/2022cousins.jpg', 'images/2024family.jpg'];

function BackgroundImage({ children }) {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);  
  
  // Using useMemo to ensure the background image selection is only recalculated when the component mounts
    const backgroundImage = useMemo(() => {
      return bgImages[Math.floor(Math.random() * bgImages.length)];
    }, []); // Empty dependency array means this will only run once when the component mounts
  
    useEffect(() => {
      const handleResize = () => setViewportHeight(window.innerHeight);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const style = {
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      position: 'relative', 
      width: '100%',
      minHeight: '100vh',
      height: 'auto',
    };
    
  
    return <div style={style}>{children}</div>;
  }

// Wrap the component in React.memo to prevent unnecessary re-renders
export default React.memo(BackgroundImage);