/* Create a letter based on a kid and a gift. */
import React, { useState, useEffect } from 'react';
import Axios from 'axios';

const kids = [
  "paul",
  "dani",
  "luc",
  "sam",
  "inde",
  "kailand",
  "alenna",
  "nova",
  "avon",
  "jack",
  "ella",
];
const chosenKid = kids[Math.floor(Math.random() * kids.length)];

function GiftLetter() {
  const [gift, setGift] = useState("Something nice");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const url = `${process.env.REACT_APP_HOST}/rndGift`;
    Axios.post(url, {
      kid: chosenKid,
    }).then((response) => {
      const giftData = typeof response.data === "string" ? response.data : JSON.stringify(response.data);
      setGift(giftData);
      setLoading(false);
    }).catch((err) => {
      console.error("There was an error fetching a random gift.");
      setGift("Something nice")
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <p>Writing a letter to Santa...</p>
  }

  return (
    <div id="homeGift">
      <p>Dear Santa,</p>
      <br />
      <p>
        I have been incredibly good this year and hope you can bring me {gift}{" "}
        for Christmas if you aren't too busy. Thanks, and please say "hi" to
        Mrs. Claus and the elves for me.
      </p>
      <br />
      <p>Your Friend on the Nice List,</p>
      <p>{chosenKid}</p>
      <img src={`images/kids/${chosenKid}.jpg`} alt={chosenKid} />
    </div>
  );
}

export default GiftLetter;
