/* User Navigation Menu */
import {useState, useEffect} from 'react';
import Axios from 'axios';

import HamburgerMenu from './HamburgerMenu';

function Nav() {
    const [users, setUsers] = useState([]);
    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    const toggleHamburger = () => {
        setHamburgerOpen(!hamburgerOpen);
    };

    const userPage = (event) => {
        event.preventDefault(event);
        localStorage.setItem("userPage", event.target.innerText);
        window.location.reload();
    };

    useEffect(() => {
        if (document.querySelector("#userMenu"))
        {
            const url = `${process.env.REACT_APP_HOST}/users`;
            Axios.post(url).then((response) => {
                const userNames = Object.values(response.data).map(user => user.fullName);
                setUsers(userNames); 
            }).catch((error) => {
                console.log("The user menu did not load because of an error.")
            });
        }
      }, []);

    return (
        <div>
            <nav id="userMenu">
                <ul>
                    {users.length > 0 ? (
                    users.map((user) => (
                        <li key={user}>
                        <a href="#" onClick={userPage} role="button">
                            {user}
                        </a>
                        </li>
                    ))
                    ) : (
                    <li>Loading users...</li>
                    )}
                </ul>
            </nav>

            <div className="hamburger" onClick={toggleHamburger}>
                <HamburgerMenu isOpen={hamburgerOpen} />
            </div>

            <style jsx="true">{`
                #userMenu ul {
                display: ${hamburgerOpen ? "inline" : "none"};
                background-color: green;
                margin-top: 30px;
                position: absolute;
                padding: 5px;
                font-size: x-large;
                border-radius: 15px;
                border: solid white 2px;
                }

                @media (min-width: 700px) {
                #userMenu ul {
                    display: inline;
                    margin-top: 0px;
                    font-size: medium;
                }
                }
            `}</style>
        </div>
    );
}

export default Nav;