// Heading for logged-in user or gift recipient

import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import Cookies from 'js-cookie';
import { useContext } from 'react';

import { AuthContext } from '../authContext';
import Nav from '../components/Nav';

function UserHeading({ name }) {
  Axios.defaults.withCredentials = true;
  
  const navigate = useNavigate();
  const { setUser } = useContext(AuthContext);
  
  async function logout() {
    const url = `${process.env.REACT_APP_HOST}/logout`;
    await Axios.post(url).then((response) => {
        localStorage.removeItem("username");
        localStorage.removeItem("fullName");
        localStorage.removeItem("userPage");
        localStorage.removeItem("user");
        document.cookie = "jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        Cookies.remove("jwt", { path: "/", domain: ".derekmiller.us" });
        setUser(false);
        navigate("/");
    });
  }
  return (
    <div id="heading">
      <button className="logout-btn" onClick={logout}>
        Sign Out
      </button>
      
      <Nav/>

      <h1 className="userHeading">
        {localStorage.getItem("userPage")
          ? localStorage.getItem("userPage") + "'s List"
          : localStorage.getItem("fullName") + "'s List"}
      </h1>
    </div>
  );
}

export default UserHeading;
