// Gift components that are display in user Christmas lists
import {useState} from 'react';
import Axios from 'axios';
import Modal from 'react-bootstrap/Modal';

function Gift({ type, value, gifts, setGifts, onMessage }) {
    const [gift, setGift] = useState(-1);
    const [showDelete, setShowDelete] = useState(false);
    const [showPurchase, setShowPurchase] = useState(false);

    const handleShowDelete = () => setShowDelete(true);
    const handleCloseDelete = () => setShowDelete(false);
    const handleShowPurchase = () => setShowPurchase(true);
    const handleClosePurchase = () => setShowPurchase(false);

    const handleDelete = (id) => {
        setGift(id);
        handleShowDelete();
    };

    const handlePurchase = (id) => {
        setGift(id);
        handleShowPurchase();
    };
   
    const confirmDelete = () => {
        const url = `${process.env.REACT_APP_HOST}/deleteGift`;
        Axios.post(url, { gift }).then((response) => {
            const updatedGifts = gifts.filter((g) => g.key !== gift);
            setGifts(updatedGifts);
            onMessage(response.data.message || response.data);
            handleCloseDelete();
        }).catch((error) => {
            onMessage("There was a problem deleting your gift.");
        });
    };

    const confirmPurchase = () => {
        const url = `${process.env.REACT_APP_HOST}/purchaseGift`;
        Axios.post(url, {
          gift,
          name: localStorage.getItem("fullName"),
        }).then((response) => {
            setGifts(response.data);
            onMessage("You have successfully purchased that gift.");
            handleClosePurchase();
          }).catch((error) => {
            onMessage("There was a problem purchasing that gift.");
          });
    };

    return (
        <>
            <Modal show={showDelete} centered onHide={handleCloseDelete}>
                <Modal.Header>
                    <Modal.Title>Delete Gift</Modal.Title>
                </Modal.Header>
                <Modal.Body>Please confirm that you would like to delete this gift.</Modal.Body>
                <Modal.Footer>
                    <button onClick={handleCloseDelete}>Cancel</button>
                    <button onClick={confirmDelete}>Delete</button>
                </Modal.Footer>
            </Modal>

            <Modal show={showPurchase} centered onHide={handleClosePurchase}>
                <Modal.Header>
                    <Modal.Title>Purchase Gift</Modal.Title>
                </Modal.Header>
                <Modal.Body>Please confirm that you would like to purchase this gift.</Modal.Body>
                <Modal.Footer>
                    <button onClick={handleClosePurchase}>Cancel</button>
                    <button onClick={confirmPurchase}>Purchase</button>
                </Modal.Footer>
            </Modal>
            
            <div id={"uGift"+value.key}>
            {type === "delete" ? (
                <>
                    <button
                        className="delete-btn"
                        value={value.key}
                        key={"btn-"+value.key}
                        onClick={() => handleDelete(value.key)}
                    >
                        {type}
                    </button>
                    {value.link !== "" ? (
                        <>
                            <a href={value.link}>{value.gift}</a> -{" "}
                            <span>${value.price.toFixed(2)}</span>
                        </>
                    ) : (
                        <span>
                            {value.gift} - ${value.price.toFixed(2)}
                        </span>
                    )}
                </>
            ) :(
                <>
                    <button
                        className="purchase-btn"
                        value={value.key}
                        key={"btn-"+value.key}
                        disabled={parseInt(value.bought, 10) === 1}
                        onClick={() => handlePurchase(value.key)}
                    >
                        {type}
                    </button>
                    {
            parseInt(value.bought, 10) === 1 ? (
              <span style={{ textDecoration: "line-through" }}>
                {value.link !== "" ? (
                  <>
                    <a href={value.link}>{value.gift}</a> -{" "}
                    <span>
                      ${value.price.toFixed(2)} by {value.giver}
                    </span>
                  </>
                ) : (
                  <span>
                    {value.gift} - ${value.price.toFixed(2)} by {value.giver}
                  </span>
                )}
              </span>
            ) : (
              <>
                {value.link !== "" ? (
                  <>
                    <a href={value.link}>{value.gift}</a> -{" "}
                    <span>${value.price.toFixed(2)}</span>
                  </>
                ) : (
                  <span>
                    {value.gift} - ${value.price.toFixed(2)}
                  </span>
                )}
              </>
            )}
          </>
        )}
      </div>
        </>
    );
}

export default Gift;
