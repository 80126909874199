import React, { createContext, useState, useEffect } from "react";
import Axios from "axios";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    
    // Try to get user from localStorage on initial load
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : false;
  });

  const [loading, setLoading] = useState(false);

  const verifyLogin = async () => {
    try {
      setLoading(true);
      
      // Send a request to the server to verify the JWT
      const url = `${process.env.REACT_APP_HOST}/login`;
      const response = await Axios.get(url,{
          withCredentials: true,
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
      });

      if (response.data.loggedIn == true) {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        setUser(response.data.user);
      } else {
        setUser(false);
      }
    } catch (error) {
      console.warn("Error fetching data: ", error);
      setUser(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    verifyLogin();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, loading, setLoading }}>
      {children}
    </AuthContext.Provider>
  );
};