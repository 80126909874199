import React, { useState, useEffect } from 'react';
import Axios from 'axios';

import UserGift from '../components/UserGift';
import UserList from '../components/UserList';

function UserPage() {
    const [statusMessage, setStatusMessage] = useState("");
    const [gifts, setGifts] = useState([]);

    const handleStatusMessage = (message) => {
        setStatusMessage(message);
        setTimeout(() => setStatusMessage(''), 5000);
    };

    // Fetch gifts initially
    useEffect(() => {
        const fetchGifts = async () => {
            if (localStorage.getItem("userPage") == null || localStorage.getItem("userPage") === localStorage.getItem("fullName"))
            {
                const url = `${process.env.REACT_APP_HOST}/userList`;
                const response = await Axios.post(url, {
                    username: localStorage.getItem("username") || "",
                });
                const fetchedGifts = Object.values(response.data).map((gift) => ({
                    ...gift,
                }));
                setGifts(fetchedGifts);
            }
            else {
                try {
                    const userUrl = `${process.env.REACT_APP_HOST}/getUsername`;
                    const userResponse = await Axios.post(userUrl, { name: localStorage.getItem("userPage") });
    
                    const username = userResponse.data[0].username;
    
                    const giftUrl = `${process.env.REACT_APP_HOST}/userList`;
                    const giftsResponse = await Axios.post(giftUrl, { username: username });
    
                    const fetchedGifts = Object.values(giftsResponse.data).map((gift) => ({
                        ...gift,
                    }));
    
                    setGifts(fetchedGifts);
                } catch (error) {
                    console.log('Error fetching user data or gifts.');
                }
            }
        };
        fetchGifts();
    }, []);

    return (
            <>
            {
                localStorage.getItem("userPage") == null ||
                localStorage.getItem("userPage") === localStorage.getItem("fullName")
                ? <div>
                    <UserGift 
                        statusMessage={statusMessage}
                        setStatusMessage={setStatusMessage}
                        gifts={gifts}
                        setGifts={setGifts}
                    />
                </div>
                : null
            }
            <UserList 
                onMessage={handleStatusMessage}
                gifts={gifts}
                type={localStorage.getItem("userPage") == null || localStorage.getItem("userPage") === localStorage.getItem("fullName") ? "delete" : "purchase"}
                setGifts={setGifts}
            />
            </>
    );
}

export default UserPage;