import React from 'react';

const InputField = ({ label, type, placeholder, value, onChange, name }) => {
    return (
        <div className="input-field">
            {label && <label htmlFor={name}>{label}</label>}
            <input
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange} 
                name={name} 
                id={name}
            />
        </div>
    );
};

// Setting default props
InputField.defaultProps = {
    value: '', 
    type: 'text',
    placeholder: '',
};

export default InputField;
