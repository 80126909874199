import React, {useContext, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Axios from 'axios';
import Cookies from 'js-cookie';

import { AuthContext } from '../authContext';
import InputField from '../components/InputField';
import Button from '../components/Button';

function Home() {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });
    const [loginStatus, setLoginStatus] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const { setUser } = useContext(AuthContext);

    const login = (event) => {
        event.preventDefault();
        setShowLoader(true);
        const url = `${process.env.REACT_APP_HOST}/login`;
        Axios.post(url, {
            username: formData.username,
            password: formData.password,
        }).then((res) => {
            setShowLoader(false);
            localStorage.setItem("username", formData.username);
            localStorage.setItem("fullName", res.data.user.fullName);
            localStorage.setItem("userPage", res.data.user.fullName);

            if (res.data.token) {
                // Store the token in a cookie
                Cookies.set("jwt", res.data.token, { expires: 1 });
                setUser(true);
                navigate("/");
              } else {
                setLoginStatus(res.data.message);
              }
        }).catch((error) => {
            setShowLoader(false);
            setLoginStatus("There was a problem signing in.");
        });
    };

    return (       
        <form className='login' onSubmit={login}>
            <InputField
                label="Username&nbsp;"
                value={formData.username}
                onChange={handleInputChange}
                name="username"
            />

            <InputField
                label="Password&nbsp;"
                type="password"
                value={formData.password}
                onChange={handleInputChange}
                name="password"
            />

            <div className="userLinks">
                <Link
                  to="/forgot"
                  state={{ username: formData.username }}
                >
                    Forgot Password
                </Link> 
                &nbsp;•&nbsp;
                <Link to="/register">Register</Link>
            </div>
            <br />
            
            <div className="buttonContainer">
                <Button
                    text="Sign In"
                    loading={showLoader}
                    disabled={showLoader}
                />
            </div>

            <div className="status">
                <h2>{loginStatus}</h2>
            </div>
        </form>
    );
}

export default Home;