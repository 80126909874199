import React from 'react';
import { Outlet } from 'react-router-dom';

import BackgroundImage from './BackgroundImage';
import Heading from './Heading';
import GiftLetter from './GiftLetter';
import UserHeading from './UserHeading';

function Layout(props) {
    return (
        <div className="App">
            <BackgroundImage>
                <div id="mainContent">
                    {props.userPage ? <UserHeading/> : <Heading/>}
                    <Outlet />
                    {props.userPage ? '' : <GiftLetter/>}
                    
                </div>
            </BackgroundImage>
        </div>
    );
}

export default Layout;
