/* Site Heading */
import HomeButton from './HomeButton';

function Heading() {
    return (
        <header>
            <HomeButton/><h1>A Miller Family Christmas</h1>
        </header>
    );
}

export default Heading;