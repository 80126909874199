import React, {useState} from 'react';
import Axios from 'axios';
import { Link, useLocation } from 'react-router-dom';

import InputField from '../components/InputField';
import Button from '../components/Button';

function Register() {
    const location = useLocation();
    const username = location.state?.username || '';
    const [formData, setFormData] = useState({
        username: username,
        fullName: '',
        password: '',
        confirmPassword: '',
    });
    const [registrationStatus, setRegistrationStatus] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [showLink, setShowLink] = useState(true);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const validatePasswords = () => formData.password === formData.confirmPassword;

    const register = async (event) => {
        event.preventDefault();

        if (!validatePasswords()) {
            setRegistrationStatus('Your password and confirmation do not match.');
            return;
        }

        setShowLoader(true);
        const url = `${process.env.REACT_APP_HOST}/register`;
        Axios.post(url, {
          username: formData.username,
          password: formData.password,
          fullName: formData.fullName,
        }).then((response) => {
            setShowLoader(false);
            setRegistrationStatus(response.data.message);
            setShowLink(true);
          }).catch((error) => {
            setShowLoader(false);
            setRegistrationStatus("There was a problem registering.");
          });
      };
    
      return (
        <div className='registration'>
            <h2>Register New Account</h2>
            <form onSubmit={register}>
                <InputField
                    label="Username&nbsp;"
                    type="text"
                    value={formData.username}
                    onChange={handleInputChange}
                    name="username"
                />

                <InputField
                    label="Full Name"
                    type="text"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    name="fullName"
                />

                <InputField
                    label="Password&nbsp;"
                    type="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    name="password"
                />
                
                <InputField
                    label="Confirm&nbsp;&nbsp;"
                    type="password"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    name="confirmPassword"
                />
                <br />

                <div className="buttonContainer">
                    <Button
                        text={"Register"}
                        loading={showLoader}
                        disabled={showLoader}
                    />
                </div>
            </form>

            <br/>
            <div className="status">
                <h3>{registrationStatus}</h3>
                <h3 style={{ display: showLink ? "block" : "none" }}>
                   <Link to="/" className="returnHome">
                        Return to Home
                    </Link>
                </h3>
            </div>
        </div>
      );
}

export default Register;