// List of user gifts
import Gift from "./Gift";

function UserList({ gifts, setGifts, type, onMessage }) {
    return (
        <div id="giftList">
            {gifts.length > 0 ? (
                gifts.map((gift) => (
                    <Gift
                        type={type}
                        value={gift}
                        key={gift.key}
                        onMessage={onMessage}
                        gifts={gifts}
                        setGifts={setGifts}
                    />
                ))
            ) : (
                <div className="noGifts">
                    <h3>No gifts have been added to this list.</h3>
                </div>
            )}
        </div>
    );
}

export default UserList;
