import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React, {useContext} from 'react';
import ReactDOM from 'react-dom/client';
import {Space, Spin} from 'antd';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import { AuthContext, AuthProvider } from './authContext';
import Layout from './components/Layout';
import Home from './pages/Home';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword'; 
import UserPage from './pages/UserPage';

export default function App() {
  const {user, loading} = useContext(AuthContext);
  
  return (
    <div>
      {loading ? (
        <div className="loader">
          <Space size="middle">
            <Spin size="large" />
          </Space>
        </div>
      ) : (
        <Routes>
          {/* Shared layout */}
          <Route path="/" element={<Layout userPage={user} />}>
            {/* Conditionally render Home or UserPage */}
            <Route index element={user ? <UserPage /> : <Home />} />
            <Route path="register" element={<Register />} />
            <Route path="forgot" element={<ForgotPassword />} />
          </Route>
        </Routes>
      )
    }
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthProvider>
    
  </React.StrictMode>
);